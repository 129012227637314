/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Flex, Box } from "@theme-ui/components"
import { Link } from "gatsby"

export default ({ children }) => (
  <Flex
    ml={[3, 5]}
    mr={3}
    sx={{ maxWidth: "720px", minHeight: "100vh", alignItems: "center" }}
  >
    <Box>
      <header>
        <nav>
          <Link
            to="/"
            sx={{
              fontWeight: "bold",
              fontSize: [1, 2],
              textTransform: "uppercase",
              color: "gray.5",
              textDecoration: "none",
            }}
          >
            Joé Jemmely
          </Link>
        </nav>
      </header>
      <main>{children}</main>
    </Box>
  </Flex>
)
