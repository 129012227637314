import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Video Player`}</h1>
    <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tincidunt sagittis massa, nec lacinia ex vestibulum efficitur. Maecenas mattis enim nec sapien consequat fermentum id vel enim. Ut pretium ullamcorper mi sollicitudin sodales. Donec efficitur semper erat ac tincidunt. Duis sit amet urna et velit consectetur finibus id sit amet neque. Phasellus sagittis tincidunt nisi, sed sodales justo varius ac. Sed eget magna id tellus venenatis malesuada. Pellentesque imperdiet id ligula vel facilisis.`}</p>
    <p>{`Phasellus et pulvinar sapien. Fusce ullamcorper cursus semper. Aenean congue vehicula elit, eu viverra nulla hendrerit vitae. Nullam tincidunt ut nisl ac luctus. Duis augue lorem, mattis sit amet odio nec, rhoncus varius diam. Morbi dapibus quam tortor, et consequat leo commodo sed. Aliquam non lacus eu sapien euismod aliquet. Quisque pharetra, risus sed tristique consequat, nisi purus mattis dui, sit amet imperdiet lacus metus vitae mauris. Phasellus dolor mi, ornare nec consectetur ut, vulputate ac quam. Phasellus facilisis, velit pellentesque facilisis volutpat, magna dui gravida arcu, nec tristique urna orci vestibulum augue. Cras eget metus laoreet, accumsan ante sed, feugiat nibh. Nulla eget tincidunt diam. Nullam eu nisi porttitor, mollis tortor ac, hendrerit dui. Aliquam gravida, urna quis suscipit mattis, elit elit faucibus mauris, eu viverra ex erat et ex. Integer non urna congue, finibus augue id, eleifend erat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      